import { detect } from "../../libs/detect-browser/";

window.$ = jQuery;

const browser = detect();
let unsupported = false;
if ( !sessionStorage.getItem( 'showDetectBrowser' ) ) {
    let version = browser.version.split('.');

    switch ( browser && browser.name ) {
        case "edge-chromium":
            unsupported = version[0] < 87;
            break;
        case "edge-ios":
            unsupported = version[0] < 87;
            break;
        case "firefox":
            unsupported = version[0] < 83;
            break;
        case "fxios":
            unsupported = version[0] < 95;
            break;
        case "chrome":
            unsupported = version[0] < 80;
            break;
        case "crios":
            unsupported = version[0] < 79; // crios starts from 80 version
            break;
        case "safari":
            unsupported = version[0] < 12;
            break;
        case "opera":
            unsupported = version[0] < 77;
            break;
        case "ios":
            unsupported = version[0] < 12;
            break;
        case "samsung":
            unsupported = version[0] < 13;
            break;
        default:
            unsupported = true;
            break;
    }

    if ( unsupported ) {
        $.fancybox.open( $( "#js-popup-unsupported-browser" ), {
            hideScrollbar: true
        } );
    }

    sessionStorage.setItem( 'showDetectBrowser', 'showed' );
}